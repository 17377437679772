<template>
  <div>
    <template v-if="!loading">
      <template v-if="flow.status === 'COMPLETED'">
        <PageContainer
          :title="flow.title.text[language.lang]"
          :back="actions.back"
        >
          <template v-slot:content>
            <div class="page-segment">
              <div class="page-segment-content">
                <Heading
                  class="margin-bottom"
                  :text="$translations.documents.patient"
                />
                <PersonCard
                  :image="appointment.patient.profile_picture"
                  :title="`${appointment.patient.first_name} ${appointment.patient.last_name}`"
                  :first-line="cardFirstLine"
                  :middle-line="cardMiddleLine"
                />
              </div>
            </div>

            <!-- statment -->
            <div class="page-segment">
              <div class="page-segment-content">
                <Heading
                  class="margin-bottom"
                  :text="$translations.documents.enunciate"
                />
                <div
                  class="body"
                  v-text="v"
                  v-for="(v, index) in enunciates"
                  :key="index"
                />
              </div>
            </div>

            <!-- score -->
            <div class="page-segment">
              <div class="page-segment-content">
                <Heading
                  :text="$translations.documents.score"
                  :action="actions.scoreAction"
                />
                <template v-if="!hideSection">
                  <div class="score-map">
                    <div
                      class="score-map-item"
                      v-for="(i, k) in blockOptionsScore"
                      :key="k"
                    >
                      <div class="content-title" v-text="optionValue(i)" />
                      <div
                        class="body score-map-item-response"
                        v-text="i.text[language.lang]"
                      />
                    </div>
                  </div>

                  <div class="score-table-result">
                    <div class="score-table-result-item">
                      <div
                        class="content-title"
                        v-text="$translations.documents.item"
                      />
                      <div
                        class="content-title score-table-result-item-response"
                        v-text="$translations.documents.response"
                      />
                    </div>
                    <div
                      class="score-table-result-item"
                      v-for="(i, k) in blockOptions"
                      :key="k"
                    >
                      <div class="body" v-text="i.title[language.lang]" />
                      <div
                        class="body score-table-result-item-response"
                        v-text="optionValue(i.answerRaw)"
                      />
                    </div>
                    <div class="score-table-result-item">
                      <div
                        class="content-title"
                        v-text="$translations.documents.score"
                      />
                      <div
                        class="content-title score-table-result-item-response"
                        v-text="flow.answer.score"
                      />
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <!-- score result -->
            <div class="page-segment">
              <div class="page-segment-content">
                <Heading :text="$translations.documents.result" />
                <div class="score-table margin-top">
                  <div
                    class="content-title"
                    v-text="$translations.documents.subscale"
                  />
                  <div
                    class="content-title"
                    v-text="$translations.documents['related-item']"
                  />
                  <div
                    class="content-title"
                    v-text="$translations.documents.range"
                  />
                  <div
                    class="content-title"
                    v-text="$translations.documents.score"
                  />
                </div>
                <div
                  class="score-table"
                  v-for="(i, index) in subscale"
                  :key="index"
                >
                  <div class="body" v-text="i.text" />
                  <div class="body" v-text="i.related" />
                  <div class="body" v-text="i.range" />
                  <div class="body" v-text="flow.answer.score" />
                </div>
              </div>
            </div>

            <!-- interpretation -->
            <div class="page-segment">
              <div class="page-segment-content">
                <Heading :text="$translations.documents.interpretation" />
                <div class="body margin-y" v-html="interpretation" />
                <ul class="strategy-list">
                  <li
                    class="body"
                    v-text="item"
                    v-for="(item, index) in strategyMap"
                    :key="index"
                  />
                </ul>
              </div>
            </div>
          </template>
        </PageContainer>
      </template>
      <FlowResolver
        :flow="flow"
        :router="$router"
        :route="$route"
        origin="specialist"
        :lang="language.lang"
        v-else
      />
    </template>
  </div>
</template>

<script>
import {
  FlowResolver,
  Flow,
  Instruments,
  PageContainer,
  PersonCard,
  Appointments,
  Heading
} from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'Document',
  components: {
    FlowResolver,
    PageContainer,
    PersonCard,
    Heading
  },
  data () {
    return {
      flow: null,
      flowRaw: null,
      appointment: null,
      loading: false,
      hideSection: false,
      actions: {
        back: {
          callback: () => {
            if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.push({
                name: 'Home'
              })
            }
          }
        },
        scoreAction: {
          icon: 'icon-arrow-big-outline-down',
          callback: () => this.toggleSection()
        }
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      if (this.$route.query.answerId) {
        const answerId = this.$route.query.answerId
        this.flow = await Instruments.getInstrumentByAnwserId(answerId)
        this.flowRaw = await Flow.get(this.flow.key)

        this.appointment = await Appointments.getAppointmentDetail(
          this.flow.reference_id
        )

        this.loading = false
        return
      }

      const key = this.$route.params.key
      this.flow = await Flow.get(key)
      this.loading = false
    },
    patientAge () {
      const birthday = this.$moment(this.appointment.patient?.birthday).locale(
        this.user.language
      )
      const now = this.$moment(new Date()).locale(this.user.language)
      return now.diff(birthday, 'years')
    },
    toggleSection () {
      this.hideSection = !this.hideSection
      this.actions.scoreAction.icon = this.hideSection
        ? 'icon-arrow-big-outline-up'
        : 'icon-arrow-big-outline-down'
    }
  },
  computed: {
    ...mapGetters({
      language: 'language',
      user: 'auth/user'
    }),
    cardFirstLine () {
      const gender = this.$translations.gender[this.appointment.patient.gender]
      return `${gender} • ${this.patientAge()} ${
        this.$translations.appointment.create.years
      }`
    },
    cardMiddleLine () {
      return this.appointment?.patient?.address?.country
        ? `${this.appointment?.patient?.address?.country}`
        : ''
    },
    blockOptionsScore () {
      return this.flowRaw.blocks[1].options[0].options
    },
    optionValue () {
      return (option) => {
        if (option?.mapping) {
          return (
            Object.values(option.mapping[0]).find((value) => value > 0) || 0
          )
        }

        return 0
      }
    },
    blockOptions () {
      return this.flow.blocks[1].options
    },
    enunciates () {
      return this.flowRaw.blocks[1].resolver_strategy.meta.enunciated.map(
        (item) => {
          return item[this.language.lang]
        }
      )
    },
    subscale () {
      return this.flowRaw.blocks[1].resolver_strategy.meta.subscale.map(
        (subscale) => {
          return {
            text: subscale.text[this.language.lang],
            related: `${subscale.items.min}-${subscale.items.max}`,
            range: `${subscale.range.min}-${subscale.range.max}`
          }
        }
      )
    },
    interpretation () {
      return this.flowRaw.blocks[1].resolver_strategy.meta.interpretation[
        this.language.lang
      ].replace(
        '{scale}',
        `<b>${this.flow.answer.text[this.language.lang]}</b>`
      )
    },
    strategyMap () {
      return this.flow.answer.map.map(
        (item) => `${item.min}-${item.max} ${item.text[this.language.lang]}`
      )
    }
  },
  watch: {
    loading () {
      if (this.loading) {
        this.$loader.handleShowLoader({
          lang: this.$store.getters.language.lang,
          type: 'main-transparent',
          key: 'NO_MESSAGE'
        })
      } else {
        this.$loader.handleRemoveLoader()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.score-map
  margin: 16px 0
  &-item
    display: flex
    gap: 8px

.score-table-result
  &-item
    display: grid
    grid-template-columns: 70% 1fr
    padding: 8px 0
    border-bottom: 1px solid var(--gray-20)
    &:last-child
      border-bottom: none

    &-response
      align-self: center
      justify-self: center
      vertical-align: middle

.score-table
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr
  align-items: center
  padding: 8px 0
  border-bottom: 1px solid var(--gray-20)
  &:last-child
    border-bottom: none

.strategy-list
  margin: 16px 0
  padding: 0 22px
  list-style: disc
</style>
